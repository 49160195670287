import {
  List,
  Datagrid,
  SearchInput,
  TextField,
  FunctionField,
} from "react-admin";
import { DatagridActionsColumn } from "../shared/components/DatagridActionsColumn";
import { RowButton } from "../shared/components/RowButton";
import { centsToUsd } from "../utils/format";

const campaignsFilter = [
  // https://github.com/hasura/ra-data-hasura#nested-filtering
  <SearchInput source="name@_ilike" alwaysOn />,
];

export const CampaignsList = (props: any) => {
  return (
    <List
      {...props}
      sort={{ field: "created_at", order: "DESC" }}
      filters={campaignsFilter}
      sx={{
        padding: "24px",
      }}
    >
      <Datagrid>
        <TextField label="Campaign name" source="name" />
        <FunctionField
          label="Merchant name"
          render={(record: any) => record?.client_profile?.company_name}
        />
        <TextField label="Product name" source="product_name" />
        <FunctionField
          label="Campaign wage"
          render={(record: any) => centsToUsd(record?.budget_cash_usd_cents)}
        />
        <DatagridActionsColumn label="Actions">
          <RowButton label="View" />
        </DatagridActionsColumn>
      </Datagrid>
    </List>
  );
};
