import { Datagrid, List, TextField } from "react-admin";
import { useParams } from "react-router-dom";

export const TransactionHistoryList = () => {
  const params = useParams();

  return (
    <List filter={{ profile_id: params?.id }}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="credit" />
        <TextField source="debit" />
        <TextField source="description" />
        <TextField source="timestamp" />
        <TextField source="amount_usd_cents" />
      </Datagrid>
    </List>
  );
};
