import { Edit, FunctionField, SimpleForm, TextInput } from "react-admin";
import styled from "styled-components";
import { formatCampaignDate, formatCampaignPeriod } from "../utils/time";
import { centsToUsd } from "../utils/format";

export const CampaignForm = () => {
  return (
    <Edit>
      <SimpleForm style={{ maxWidth: 600 }} toolbar={false}>
        <h2>Campaign details</h2>
        <FunctionField
          label="Merchant name"
          render={(record: any) => (
            <div>
              {record?.images?.map((x: any, i: number) => (
                <img
                  src={x?.file_metadata?.url}
                  width={120}
                  height={120}
                  alt={`campaign image ${i + 1}`}
                />
              ))}
            </div>
          )}
        />
        <TextInput fullWidth disabled label="Campaign name" source="name" />
        <TextInput fullWidth disabled label="Brand name" source="brand_name" />
        <TextInput
          fullWidth
          disabled
          label="Product name"
          source="product_name"
        />
        <TextInput
          fullWidth
          disabled
          label="Campaign description"
          source="description"
        />
        <OneLine>
          <FunctionField
            label="Campaign period"
            style={{ width: "100%" }}
            render={(record: any) => (
              <TextInput
                defaultValue={formatCampaignPeriod(
                  record?.start_date,
                  record?.end_date
                )}
                source=""
                label="Campaign period"
                disabled
                fullWidth
              />
            )}
          />
          {/* <TextInput
            disabled
            label="Recruitment deadline"
            source="recruitment_end_date"
          /> */}
          <FunctionField
            label="Recruitment deadline"
            style={{ width: "100%" }}
            render={(record: any) => (
              <TextInput
                defaultValue={formatCampaignDate(record?.recruitment_end_date)}
                source=""
                label="Recruitment deadline"
                disabled
                fullWidth
              />
            )}
          />
        </OneLine>
        <h2>Kol requirements</h2>
        <FunctionField
          fullWidth={true}
          style={{ width: "100%" }}
          render={(record: any) => {
            return record?.tranches?.map((x: any, i: number) => (
              <div>
                <h3>{`Group ${i + 1}`}</h3>
                <div style={{ paddingLeft: 20 }}>
                  <TextInput
                    defaultValue={x?.product_categories
                      ?.map((i: any) => i?.product_category?.name)
                      ?.join(", ")}
                    source=""
                    label="KOL affinity"
                    disabled
                    fullWidth
                    multiline={true}
                  />
                  <OneLine>
                    <TextInput
                      defaultValue={x?.min_follower_size}
                      source=""
                      label="Min followers"
                      disabled
                      fullWidth
                    />
                    <TextInput
                      defaultValue={x.max_follower_size}
                      source=""
                      label="Max followers"
                      disabled
                      fullWidth
                    />
                  </OneLine>
                  <OneLine>
                    <TextInput
                      defaultValue={x?.social_platforms
                        ?.map((i: any) => i?.social_platform?.name)
                        ?.join(", ")}
                      source=""
                      label="Platform"
                      disabled
                      fullWidth
                    />
                    <TextInput
                      defaultValue={x?.genders
                        ?.map((i: any) => i?.gender?.description)
                        ?.join(", ")}
                      source=""
                      label="Gender"
                      disabled
                      fullWidth
                    />
                  </OneLine>
                  <TextInput
                    defaultValue={x?.age_groups
                      ?.map((i: any) => i?.age_category?.name)
                      ?.join(", ")}
                    source=""
                    label="Age group"
                    disabled
                    fullWidth
                  />
                  <OneLine>
                    <TextInput
                      defaultValue={x?.number_kols}
                      source=""
                      label="Num of KOLs"
                      disabled
                      fullWidth
                    />
                    <TextInput
                      defaultValue={"Somewhere"}
                      source=""
                      label="Location"
                      disabled
                      fullWidth
                    />
                  </OneLine>
                  <h4>Deliverables and due date</h4>
                  <OneLine>
                    <TextInput
                      defaultValue={centsToUsd(x?.cash_usd_cents)}
                      source=""
                      label="Cash"
                      disabled
                      fullWidth
                    />
                    <TextInput
                      defaultValue={centsToUsd(x?.product_value_usd_cents)}
                      source=""
                      label="PIK value"
                      disabled
                      fullWidth
                    />
                  </OneLine>
                  <TextInput
                    defaultValue={"PIK description"}
                    source=""
                    label="PIK description"
                    disabled
                    fullWidth
                  />
                  <h4>Remuneration per KOL</h4>
                  <OneLine>
                    <TextInput
                      defaultValue={x?.deliverable_specs?.length || 0}
                      source=""
                      label="Number of deliverables"
                      disabled
                      fullWidth
                    />
                    <TextInput
                      defaultValue={x?.deliverable_specs
                        ?.map((i: any) => i?.content_type?.name)
                        .join(", ")}
                      source=""
                      label="Type"
                      disabled
                      fullWidth
                      multiline={true}
                    />
                  </OneLine>
                  <TextInput
                    defaultValue={x?.deliverable_specs
                      ?.map((i: any) => formatCampaignDate(i?.due_date))
                      .join(", ")}
                    source=""
                    label="Due"
                    disabled
                    fullWidth
                  />
                  <TextInput
                    defaultValue={x?.product_description || "N/A"}
                    source=""
                    label="Description"
                    disabled
                    fullWidth
                  />
                  <TextInput
                    defaultValue={x?.requirements || "N/A"}
                    source=""
                    label="Other requirements"
                    disabled
                    fullWidth
                  />
                </div>
              </div>
            ));
          }}
        />
      </SimpleForm>
    </Edit>
  );
};

const OneLine = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;

  & > * {
    flex: 1;
  }
`;
