import { format } from "date-fns";

export function formatCampaignDate(date: string | Date) {
  if (typeof date === "string") {
    date = new Date(date);
  }

  return format(new Date(date), "do MMM, yy");
}

export function formatDate(date: string | Date, formatText: string) {
  if (typeof date === "string") {
    date = new Date(date);
  }

  return format(new Date(date), formatText);
}

export function formatCampaignPeriod(start: string | Date, end: string | Date) {
  return `${formatCampaignDate(start)} - ${formatCampaignDate(end)}`;
}
