import { useState } from "react";
import styled from "styled-components";
import { ChevronDown, ChevronUp } from "react-feather";

type SocialExpandableProps = {
  icon: string;
  socialHandle: string;
  statsFollowers: number;
  statsAvgLikes: number;
  statsAvgViews: number;
  statsEngagement: number;
};

export const SocialExpandable = (props: SocialExpandableProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Container>
      <SocialExpandableHeader>
        <IconWrapper>
          <SocialIcon src={props.icon} alt="social icon" />
        </IconWrapper>
        <SocialHandle>{props.socialHandle}</SocialHandle>
        <ExpandIconWrapper onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <ChevronUp /> : <ChevronDown />}
        </ExpandIconWrapper>
      </SocialExpandableHeader>
      {isExpanded && (
        <StatsContainer>
          <div>Followers</div>
          <div>{props.statsFollowers || "-"}</div>
          <div>Avg. Likes</div>
          <div>{props.statsAvgLikes || "-"}</div>
          <div>Avg. Views</div>
          <div>{props.statsAvgViews || "-"}</div>
          <div>Engagement</div>
          <div>{props.statsEngagement || "-"}</div>
        </StatsContainer>
      )}
    </Container>
  );
};

const ExpandIconWrapper = styled.span`
  cursor: pointer;
`;

const IconWrapper = styled.span`
  margin-right: 12px;
`;

const Container = styled.div`
  width: 306px;
`;

const StatsContainer = styled.div`
  background-color: #f7f2fa;
  padding: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px 0;
  & > div:nth-child(even) {
    justify-self: end;
  }
`;

const SocialExpandableHeader = styled.div`
  display: flex;
  align-items: center;
`;

const SocialHandle = styled.span`
  margin-right: auto;
  color: #6750a4;
  font-weight: 500;
`;

const SocialIcon = styled.img`
  width: 20px;
  height: 20px;
  display: block;
`;
