import {
  useCreatePath,
  useRecordContext,
  useResourceContext,
  Button,
  ButtonProps,
} from "react-admin";
import { Link } from "react-router-dom";

const stopPropagation = (e: any) => e.stopPropagation();

export const RowButton = (props: ButtonProps) => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const createPath = useCreatePath();

  return (
    <Button
      to={createPath({ type: "edit", resource, id: record?.id })}
      onClick={stopPropagation}
      component={Link}
      color="secondary"
      variant="outlined"
      {...props}
    />
  );
};
