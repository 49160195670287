import { useQuery } from "@apollo/client";
import { GET_METRICS } from "../queries/metric";
import { KolMetric } from "../types/kolProfile";

export const useMetrics = (kol_profile_id: string) => {
  const { data } = useQuery<{
    kol_metrics: KolMetric[];
  }>(GET_METRICS, {
    variables: {
      kol_profile_id,
    },
  });

  return {
    data: data?.kol_metrics?.[0],
  };
};
