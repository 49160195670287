import { gql } from "@apollo/client";

export const GET_METRICS = gql`
  query GetMetrics($kol_profile_id: uuid!) {
    kol_metrics(where: { kol_profile_id: { _eq: $kol_profile_id } }) {
      id
      user_id
      kol_profile_id
      total_engagement
      total_followers_count
      updated_at
      created_at
      kol_audience_demographic_data {
        id
        category
        created_at
        data
        updated_at
        kol_metric_id
      }
      kol_metrics_by_platforms {
        engagement
        followers_count
        created_at
        id
        impression_rate
        platform
        reach_rate
        updated_at
        kol_metric_id
      }
    }
  }
`;
