import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useContext, useMemo, useState } from "react";
import { ENV } from "../configs/env";
import { setContext } from "@apollo/client/link/context";

export const HasuraUserContext = createContext<null | string>(null);

export const useHasuraUserId = () => {
  const context = useContext(HasuraUserContext);

  return context;
};

type ApolloProviderComposerProps = {
  children: React.ReactNode;
};

export const ApolloProviderComposer = ({
  children,
}: ApolloProviderComposerProps) => {
  const { getAccessTokenSilently, isAuthenticated, getIdTokenClaims } =
    useAuth0();

  const [hasuraUserId, setHasuraUserId] = useState<null | string>(null);

  const client = useMemo(() => {
    let hasuraUserId: null | string = null;

    const httpLink = new HttpLink({
      uri: ENV.REACT_APP_APOLLO_GRAPHQL_URL,
    });

    const authLink = setContext(async (_, { headers }) => {
      let token;
      let idTokens;
      try {
        idTokens = await getIdTokenClaims();
        if (idTokens && idTokens.__raw) {
          token = idTokens?.__raw;

          hasuraUserId =
            idTokens?.["https://hasura.io/jwt/claims"]?.["x-hasura-user-id"];

          setHasuraUserId(hasuraUserId);
        }
      } catch (error) {
        // Handle or throw error accordingly.
        console.error("Error getting access token", error);
      }

      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : null,
        },
      };
    });

    const additiveLink = from([authLink, httpLink]);

    const client = new ApolloClient({
      link: additiveLink,
      cache: new InMemoryCache(),
    });

    return client;
  }, [isAuthenticated, getAccessTokenSilently]);

  return (
    <HasuraUserContext.Provider value={hasuraUserId}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </HasuraUserContext.Provider>
  );
};
