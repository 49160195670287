import { chain } from "lodash";
import { KolProfile } from "../types/kolProfile";

export const extractAvatar = (kolProfile: KolProfile) => {
  const avatar = chain(kolProfile.kol_file_metadata || [])
    .orderBy(["created_at"], ["desc"])
    .find({ type: "avatar" })
    .value();

  if (!avatar) return null;

  return avatar.file_metadata.url;
};

export const extractBankDetails = (kolProfile: KolProfile) => {
  return chain(kolProfile.kol_file_metadata || [])
    .orderBy(["created_at"], ["desc"])
    .filter({ type: "bank_details" })
    .value();
};
