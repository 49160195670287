import { gql } from "@apollo/client"

export const GET_KOL_PAYOUT = gql`
  query GetKolPayouts($limit: Int, $page: Int) {
    kol_payout(limit: $limit, offset: $page) {
      id
      wallet_id
      kol_profile_id
      user_id
      bank_name
      bank_account
      payment_name
      full_name
      total_amount
    }
    kol_payout_aggregate {
      aggregate {
        count
      }
    }
  }
`

export const GET_PAYOUT_DETAILS = gql`
  query GetKolPayoutDetails($kol_profile_id: uuid!) {
    getTransactionsByKolProfileId(kolProfileId: $kol_profile_id) {
      id
      full_name
      total_amount
      timestamp
      type
    }
  }
`

export type KolPayout = {
  id: string
  wallet_id: string
  kol_profile_id: string
  user_id: string
  bank_name: string
  bank_account: string
  payment_name: string
  full_name: string
  total_amount: number
  details: KolPayoutDetail[]
}

export type KolPayoutAggregate = {
  aggregate: {
    count: number
  }
}

export type KolPayoutDetail = {
  id: string
  full_name: string
  total_amount: number
  type: string
  timestamp: string
}
