export const SocialCodeToIcon = {
  FACEBOOK: {
    id: "icon_social_fb",
    alt: "Facebook",
  },
  INSTAGRAM: {
    id: "icon_social_insta",
    alt: "Instagram",
  },
  YOUTUBE: {
    id: "icon_social_youtube",
    alt: "Youtube",
  },
  TIKTOK: {
    id: "icon_social_tiktok",
    alt: "Tiktok",
  },
};

export const buildIconLink = (
  socialCode: keyof typeof SocialCodeToIcon,
  link: string
) => {
  const socialIcon = SocialCodeToIcon[socialCode];
  return `/assets/images/${socialIcon.id}@2x.png"`;
};
