export const PAYOUT_LIMIT_IN_USD = 100; // 100 USD

// Hasura
export enum ResourceType {
  kol_payout = "kol_payout",
  kol_profile = "kol_profile",
  client_profile = "client_profile",
  myTransactionHistory = "myTransactionHistory",
  campaign = "campaign",
  viewTransactionForPayout = 'view_transaction_for_payout'
}
