import { gql } from "@apollo/client";

export const ENABLE_DISABLE_CLIENT_PROFILE = gql`
  mutation approveClientProfile(
    $clientProfileId: uuid!
    $isApproved: Boolean
    $isActive: Boolean
  ) {
    update_client_profile_by_pk(
      pk_columns: { id: $clientProfileId }
      _set: { is_active: $isActive, is_approved: $isApproved }
    ) {
      id
      is_active
      is_approved
    }
  }
`;
