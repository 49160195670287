import { gql } from "@apollo/client";

export const ENABLE_DISABLE_KOL_PROFILE = gql`
  mutation approveKolProfile(
    $kolProfileId: uuid!
    $isApproved: Boolean
    $isActive: Boolean
  ) {
    update_kol_profile_by_pk(
      pk_columns: { id: $kolProfileId }
      _set: { is_active: $isActive, is_approved: $isApproved }
    ) {
      id
      is_active
      is_approved
    }
  }
`;

export const GET_KOL_USER_ID_BY_KOL_PROFILE_ID = gql`
query GetKOL($id: uuid!) {
  
  kol_profile_by_pk(id: $id) {
    user_id
  }
}
`


export const GET_KOL_USER_BY_ID = gql`
  query GetUser($id: uuid!) {
    user_by_pk(id: $id) {
      email
      authUserId
    }
  }
`;