import numeral from "numeral";

export function usdToCents(amount: number | string) {
  if (typeof amount === "string") {
    amount = Number(amount);
  }

  return Math.floor(amount * 100);
}

export function centsToUsd(amount: number | string) {
  if (typeof amount === "string") {
    amount = Number(amount);
  }

  return numeral(amount / 100).format("$ 0,0.00");
}

export function formatDatetime(dateTime: string | Date) {
  if (typeof dateTime === "string") {
    dateTime = new Date(dateTime);
  }

  return `${dateTime.toLocaleDateString()} ${dateTime.toLocaleTimeString()}`;
}

export function shortenUuid(uuid: string) {
  if (!uuid) return "";
  
  const splitted = uuid.split("-");

  return `...${splitted[splitted.length - 1]}`;
}
